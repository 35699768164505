// material-ui grey[500]
$nprogress-color: #9e9e9e;

@import 'nprogress/css/nprogress.scss';

#nprogress {
  & .bar,
  & .spinner {
    z-index: 9999;
  }
}
